/* eslint-disable @typescript-eslint/no-explicit-any */
import { Captcha } from '@/types/general.type';
import provider from './main.provider';

interface LoginRes {
  token: string;
  username: string;
  resetPassword?: boolean;
  resetMessage?: { ar: string; en?: string };
  sessionDuration: number;
  captcha?: Captcha;
  message?: object;
}
export default class LoginProvider {
  async userLogin(user): Promise<LoginRes> {
    const result: LoginRes = await provider.post('/api/auth', user);
    return result;
  }

  async myMansLogin(token): Promise<LoginRes> {
    const result: LoginRes = await provider.post('/api/auth/myMans', { token });
    return result;
  }

  async getCaptcha(): Promise<LoginRes> {
    const result: LoginRes = await provider.get('/api/auth/captcha');
    return result;
  }
}
