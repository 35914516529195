
import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import LoginProvider from '@/providers/login.provider';
import Toaster from '@/mixins/toaster.mixin';

@Component({})
export default class MyMansLogin extends mixins(Toaster) {
  loginProvider = new LoginProvider();

  async mounted() {
    const { token } = this.$route.params;
    if (!token) {
      this.$router.push({ name: 'notFound' });
    }
    try {
      const credentials = await this.loginProvider.myMansLogin(token);
      this.$store.commit('setUserAuth', {
        token: credentials.token,
        username: credentials.username,
        resetPassword: credentials.resetPassword,
        resetMessage: credentials.resetMessage,
        sessionDuration: credentials.sessionDuration,
      });
      this.$router.push('/');
      this.successToaster({
        ar: 'تم تسجيل الدخول بنجاح',
        en: 'You have been logged in successfully',
      });
    } catch (err) {
      this.$router.push({ name: 'notFound' });
    }
  }
}
